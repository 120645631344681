.box {
    width: 75%;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.input-container {
    display: flex;
}

.number-input {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    font-size: 18px;
    text-align: center;
}

.submit-button {
    margin: 1rem;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.settings-button {
    margin: 1rem;
    padding: 10px 20px;
    font-size: 32px;
    background-color: #c72727;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.setting-button {
    margin: 1rem;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #c72727;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.setting-button:hover {
    background-color: #d34747;
}

.scrollable-box {
    max-height: 230px; /* Beispielhöhe, anpassen wie gewünscht */
    overflow-y: auto; /* Fügt vertikalen Scrollbalken hinzu, wenn nötig */
}

.scrollable-box ol li:nth-child(even) {
    background-color: #2d2d2d; /* Hintergrundfarbe für jeden zweiten Eintrag */
}

.hint-and-tries {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Abstand zwischen der Scrollbox und den Hinweisen/Versuchen */
    text-align: center;
}

p {
    margin-top: 10px;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.disclaimer {
    color: #656565;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
}